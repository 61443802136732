import axios from "axios";
import axiosInstance from "../api-config/axiosInstance";
import showErrorMessage from "../message/showErrorMessage";

const saveAuctionCategory = async (payload) => {
  try {
    console.log("payload", payload);
    const resp = await axiosInstance.post("/saveCategory", payload);
    const dataObject = resp.data;
    console.log("response from api", dataObject);
    return dataObject;
  } catch (error) {
    console.error("Error While Otp Verify", error);
    if (error.response) {
      // Handle specific server response errors
      const errorMessage = !error.response.data.error.message
        ? error.response.data.error?._message
        : error.response.data.error.message;
      showErrorMessage(errorMessage);
      console.log("errorMessage", errorMessage);
    } else {
      // Handle other types of errors
      console.log("error", error);
    }
    throw error; // Rethrow the error to propagate it to the calling code
  }
};
const getBidingCategory = async () => {
  try {
    const response = await axiosInstance.get(`/getCategory`);

    const resposeParsing = JSON.parse(response?.data?.data);
    return resposeParsing;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const getBidingList = async (skip, perPageCount) => {
  try {
    const response = await axiosInstance.get(
      `/getBid?page=${skip}&limit=${perPageCount}`
    );
    const resposeParsing = JSON.parse(response?.data?.data);
    return resposeParsing;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const updateAuctionCategory = async (payload) => {
  try {
    console.log("payload", payload);
    const resp = await axiosInstance.post("/updateCategory", payload);
    const dataObject = resp.data;
    return dataObject;
  } catch (error) {
    console.error("Error While Otp Verify", error);
    if (error.response) {
      // Handle specific server response errors
      const errorMessage = !error.response.data.error.message
        ? error.response.data.error?._message
        : error.response.data.error.message;
      showErrorMessage(errorMessage);
      console.log("errorMessage", errorMessage);
    } else {
      // Handle other types of errors
      console.log("error", error);
    }
    throw error; // Rethrow the error to propagate it to the calling code
  }
};
const assignAuctionCategory = async (payload) => {
  try {
    console.log("payload", payload);
    const resp = await axiosInstance.post("/assignAuctionWhoBidMore", payload);
    const dataObject = resp.data;
    return dataObject;
  } catch (error) {
    console.error("Error While Otp Verify", error);
    if (error.response) {
      // Handle specific server response errors
      const errorMessage = !error.response.data.error.message
        ? error.response.data.error?._message
        : error.response.data.error.message;
      showErrorMessage(errorMessage);
      console.log("errorMessage", errorMessage);
    } else {
      // Handle other types of errors
      console.log("error", error);
    }
    throw error; // Rethrow the error to propagate it to the calling code
  }
};
const addAuction = async (dataPayload) => {
  try {
    const resp = await axiosInstance.post("/saveBid", dataPayload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return resp;
  } catch (error) {
    throw error;
  }
};
const deleteAuction = async (payload) => {
  try {
    console.log("payload", payload);
    const resp = await axiosInstance.post("/deleteBid", payload);
    const dataObject = resp.data;
    return dataObject;
  } catch (error) {
    if (error.response) {
      // Handle specific server response errors
      const errorMessage = !error.response.data.error.message
        ? error.response.data.error?._message
        : error.response.data.error.message;
      showErrorMessage(errorMessage);
      console.log("errorMessage", errorMessage);
    } else {
      // Handle other types of errors
      console.log("error", error);
    }
    throw error; // Rethrow the error to propagate it to the calling code
  }
};
const getAuctionDetailsById = async (id) => {
  try {
    // const response = await axiosInstance.get(`/vendor/getVendorOrder?page=0&limit=10&orderStatus=${queryString}&key=${(obj)?obj.key:null}`);
    const response = await axiosInstance.get(`/getBidInfo?auctionId=${id}`);
    const responseParsing = JSON.parse(response.data.data);
    return responseParsing;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const deleteAuctionCategory = async (payload) => {
  try {
    console.log("payload", payload);
    const resp = await axiosInstance.post("/deleteCategory", payload);
    const dataObject = resp.data;
    return dataObject;
  } catch (error) {
    console.error("Error While Otp Verify", error);
    if (error.response) {
      // Handle specific server response errors
      const errorMessage = !error.response.data.error.message
        ? error.response.data.error?._message
        : error.response.data.error.message;
      showErrorMessage(errorMessage);
      console.log("errorMessage", errorMessage);
    } else {
      // Handle other types of errors
      console.log("error", error);
    }
    throw error; // Rethrow the error to propagate it to the calling code
  }
};
export {
  saveAuctionCategory,
  getBidingCategory,
  updateAuctionCategory,
  deleteAuctionCategory,
  getBidingList,
  addAuction,
  deleteAuction,
  getAuctionDetailsById,
  assignAuctionCategory
};

