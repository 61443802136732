import { useEffect, useState } from "react";
import Header from "../../Auth/Dashboard/Header";
import DashboardNav from "../../Auth/Dashboard/Nav";
import { useLocation } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import fileIcon from "../../assets/PNG/fileIcon.png";
import "react-medium-image-zoom/dist/styles.css";
import Vendor_Payment_History from "./Vender_payment_history";
import {
  updateAccountStatusService,
  getVendorInfoService,
  updateVendorStatusService,
} from "../../Service/Vendor";

const VendorDetails = () => {
  const [vendorNav, setVendorNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [cardType, setCardType] = useState("");

  const openModal = (type) => {
    setCardType(type);
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  // const [currentImage, setCurrentImage] = useState(0);
  // const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("false");
  const [vendorRespInfo, setVendorRespInfo] = useState();
  const location = useLocation();

  const sampleAadhar =
    "https://aadhaarcard.co.in/wp-content/uploads/2023/04/aadhaar-card-800x445.webp";
  const onImageError = (e) => {
    e.target.src = sampleAadhar;
  };

  const samplePan =
    "https://mybillbook.in/blog/wp-content/uploads/2022/07/pan-card.png";
  const onImagePanError = (e) => {
    e.target.src = samplePan;
  };

  const sampleProfile =
    " https://st2.depositphotos.com/1104517/11965/v/450/depositphotos_119659092-stock-illustration-male-avatar-profile-picture-vector.jpg";
  const onImageProfileError = (e) => {
    e.target.src = sampleProfile;
  };
  const vendorDetailResp = location.state ? location.state.item : null;

  const getVendorInfo = async () => {
    try {
      const vendorResp = await getVendorInfoService(vendorDetailResp?.userId);
      setVendorRespInfo(vendorResp);
    } catch (error) {}
  };
  const updateAccountStatus = async (status) => {
    try {
      const updateStatus = await updateAccountStatusService(
        vendorDetailResp?.userId,
        status
      );
      setUpdateStatus("true");
      await getVendorInfo();
    } catch (error) {
      console.error("error", error);
    }
  };
  const updateVendorStatus = async (status) => {
    try {
      const updateStatus = await updateVendorStatusService(
        vendorDetailResp?.userId,
        status
      );
      setUpdateStatus("true");
      await getVendorInfo();
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    getVendorInfo();
  }, [updateStatus]);
  const renderModal = () => {
    return (
      <>
        {isOpen && (
          <div
            className="fixed mt-24 z-40 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
                onClick={closeModal}
              ></div>
              <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                {/* Modal Header */}
                <div className="bg-[#ebffdd] px-4 py-3 sm:px-6">
                  <h3
                    className="text-lg font-medium text-gray-900"
                    id="modal-title"
                  >
                    {cardType === "aadharCard" ? "Aadhar Card" : "Pan Card"}
                  </h3>
                </div>
                {/* Modal Body */}
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {cardType === "aadharCard" ? (
                    <div className="mb-3 pb-3">
                      <Zoom>
                        <img
                          src={
                            vendorRespInfo?.aadhaarUrl
                              ? vendorRespInfo?.aadhaarUrl
                              : sampleAadhar
                          }
                          alt="aadhar card"
                          onError={onImageError}
                          className="w-full h-64 object-cover"
                        />
                      </Zoom>
                    </div>
                  ) : (
                    <div className="mb-3 pb-3">
                      <Zoom>
                        <img
                          src={
                            vendorRespInfo?.panUrl
                              ? vendorRespInfo?.panUrl
                              : samplePan
                          }
                          alt="pan card"
                          onError={onImagePanError}
                          className="w-full h-64 object-cover"
                        />
                      </Zoom>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const vendorStatus = {
    Pending: "Pending",
    Rejected: "Rejected",
    Approved: "Approved",
  };
  return (
    <>
      {renderModal()}
      <main className="">
        <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
        <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
        <section className="lg:ml-[18%] pt-[43%] md:pt-[23%] lg:pt-[5%] bg-green-50 h-full flex flex-col justify-start">
          <div class="max-w-screen-xl px-5 mx-6 pb-0 pt-0 mt-8 shadow-lg bg-white rounded-lg z-0 relative">
            <div class="p-4 grid grid-cols-2 px-12  gap-6 bg-white">
              <div className="col-span-1">
                <div class="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                  <div class="grid grid-cols-5 gap-4">
                    <div class="col-span-2 text-gray-900 font-medium hover:text-indigo-600 transition duration-500 ease-in-out">
                      Name:
                    </div>
                    <div class="col-span-1 flex flex items-start justify-start">
                      :
                    </div>
                    <div class="col-span-2 font-medium">
                      {vendorRespInfo?.firstName} {vendorRespInfo?.lastName}
                    </div>

                    <div class="col-span-2 text-gray-900 font-medium hover:text-indigo-600 transition duration-500 ease-in-out">
                      Phone:
                    </div>
                    <div class="col-span-1 flex flex items-start justify-start">
                      :
                    </div>
                    <div class="col-span-2 font-medium">
                      {vendorRespInfo?.dialCode} {vendorRespInfo?.phoneNumber}
                    </div>

                    <div class="col-span-2 text-gray-700 font-medium">
                      Address:
                    </div>
                    <div class="col-span-1 flex flex items-start justify-start">
                      :
                    </div>
                    <div class="col-span-2 font-medium">
                      {vendorRespInfo?.address}
                    </div>

                    <div class="col-span-2 text-gray-700 font-medium">
                      Account Status:
                    </div>
                    <div class="col-span-1 flex flex items-start justify-start">
                      :
                    </div>
                    <div class="col-span-2 flex items-start">
                      <p
                        class={`font-medium ml-2 ${
                          vendorRespInfo?.verified.toLowerCase() ===
                            "pending" ||
                          vendorRespInfo?.verified.toLowerCase() === "rejected"
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {vendorRespInfo?.verified}
                      </p>
                    </div>
                    <div class="col-span-2 text-gray-700 font-medium">
                      Vendor Status:
                    </div>
                    <div class="col-span-1 flex flex items-start justify-start">
                      :
                    </div>
                    <div class="col-span-2 flex gap-2 items-center">
                      <select
                        class="border-[1px] border-black h-8 w-[120px] rounded-lg"
                        onChange={(e) => {
                          updateAccountStatus(e.target.value);
                        }}
                        value={vendorStatus[vendorRespInfo?.verified]}
                      >
                        <option value="Pending">Pending</option>
                        <option value="Rejected">Reject</option>
                        <option value="Approved">Approved</option>
                      </select>
                    </div>

                    <div class="col-span-2 text-gray-700 font-medium">
                      Account Status:
                    </div>
                    <div class="col-span-1 flex flex items-start justify-start">
                      :
                    </div>
                    <div class="col-span-2 flex gap-2 items-center">
                      <select
                        className="border-[1px] border-black h-8 w-[120px] rounded-lg"
                        onChange={(e) => {
                          updateVendorStatus(e.target.value);
                        }}
                        value={
                          vendorRespInfo?.accountBlocked ? "true" : "false"
                        }
                      >
                        <option value="true">Block</option>
                        <option value="false">Unblock</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 flex flex-col gap-4"> */}
                {/* <button
                  onClick={() => {
                    updateAccountStatus("Rejected");
                  }}
                  className="bg-red-500 w-2/5 py-1 text-white shadow-lg rounded-lg"
                  type="button"
                >
                  Reject
                </button> */}
                {/* <button
                  onClick={() => {
                    updateAccountStatus("Approved");
                  }}
                  className={`bg-[#5AB344] w-2/5 py-1 ml-5 text-white shadow-lg rounded-lg ${
                    vendorRespInfo?.verified.toLowerCase() === "pending"
                      ? ``
                      : `cursor-not-allowed`
                  }`}
                  type="button"
                  disabled={(vendorRespInfo?.verified.toLowerCase() === "pending")?false:true}
                >
                  Approve
                </button> */}
                {/* <div className="flex gap-2 items-center">
                  <p className="w-[120px]"> Vendor Status:</p>

                  <select
                    className="border-[1px] border-black h-8 w-[120px] rounded-lg"
                    onChange={(e) => {
                      updateAccountStatus(e.target.value);
                    }}
                  >
                    <option value="Pending">Hold</option>
                    <option value="Rejected">Reject</option>
                    <option value="Accepted">Accept</option>
                  </select>
                </div> */}
                {/* <div className="flex gap-2 items-center">
                  <p className="w-[120px]"> Account Status:</p>
                  <select
                    className="border-[1px] border-black h-8 w-[120px] rounded-lg"
                    onChange={(e) => {
                      updateVendorStatus(e.target.value);
                    }}
                    value={vendorRespInfo?.accountBlocked ? "true" : "false"}
                  >
                    <option value="true">Block</option>
                    <option value="false">Unblock</option>
                  </select>
                </div> */}
                {/* </div> */}
              </div>
              <div class="col-span-1 ">
                <div href="#" className="flex justify-center items-center">
                  <Zoom>
                    <img
                      src={
                        vendorRespInfo?.profileUrl
                          ? vendorRespInfo?.profileUrl
                          : sampleProfile
                      }
                      alt="profile"
                      onError={onImageProfileError}
                      class="h-56  bg-cover text-center rounded-full overflow-hidden"
                      title="Woman holding a mug"
                    />
                  </Zoom>
                </div>
                <div className="flex justify-center py-4 gap-6">
                  <div
                    className="flex cursor-pointer"
                    onClick={() => openModal("aadharCard")}
                  >
                    <p>Aadhar Card</p>
                    <img
                      src={fileIcon}
                      alt="location_icon"
                      className=" w-5 h-5  ml-1 mt-1 cursor-pointer"
                    />
                  </div>
                  <div
                    className="flex cursor-pointer"
                    onClick={() => openModal("panCard")}
                  >
                    <p>Pan Card</p>
                    <img
                      src={fileIcon}
                      alt="location_icon"
                      className=" w-5 h-5  ml-1 mt-1 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative">
            <Vendor_Payment_History props={vendorRespInfo?.userId} />
          </div>
        </section>
      </main>
    </>
  );
};

export default VendorDetails;
