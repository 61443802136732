import { useEffect, useState } from "react";
import DashboardNav from "../Auth/Dashboard/Nav";
import Header from "../Auth/Dashboard/Header";
import { useNavigate } from "react-router-dom";
import {
  bulkDownLoadCsvService,
  bulkDownLoadVendorList,
  vendorService,
} from "../Service/Vendor";

import Swal from "sweetalert2";

const Vendors = () => {
  const [vendorNav, setVendorNav] = useState(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  const [vendorList, setVendorList] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const perPageCount = 10;
  const navigate = useNavigate();
  const [totalItemCount, setTotalItemCount] = useState();
  const [filterPhoneNumber, setFilterPhoneNuber] = useState();
  const [filterValue, setFilterValue] = useState();
  const [isCsvPopupOpen, setIsCsvPopupOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const placeholderImage =
    "https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp";
  const onImageError = (e) => {
    e.target.src = placeholderImage;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [page]);

  const fetchData = async (obj, phoneNumber, pincode) => {
    try {
      const response = await vendorService(
        obj,
        page - 1,
        perPageCount,
        phoneNumber,
        pincode
      );

      setVendorList(response.vendor);
      setTotalItemCount(response.totalScrapCount);
      setTotalPageCount(Math.ceil(response.totalScrapCount / perPageCount));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDetails = (item) => {
    navigate("/VendorDetails", {
      state: {
        item,
      },
    });
  };
  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPageCount &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const filetrOrderBySearch = async (event) => {
    // setSearchFilter(event.target.value);
    let obj = {};

    try {
      obj.key = event.target.value;
      setFilterValue(obj)
      await fetchData(obj, filterPhoneNumber);
    } catch (error) {
      console.error("Search Error", error);
    }
  };

  const filterByPhoneNumber = async (event) => {
    // setSearchFilter(event.target.value);
    let phoneNumber = {};

    try {
      phoneNumber = event.target.value;
      setFilterPhoneNuber(phoneNumber);
      await fetchData(filterValue, phoneNumber);
    } catch (error) {
      console.error("Search Error", error);
    }
  };

  const filterByPincode = async (event) => {
    // setSearchFilter(event.target.value);
    let pincode = {};

    try {
      pincode = parseInt(event.target.value);
      await fetchData(filterValue, filterPhoneNumber, pincode);
    } catch (error) {
      console.error("Search Error", error);
    }
  };
  const bulkCsvDownloadEvent = async () => {
    try {
      if (!startDate) {
        Swal.fire({
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 1500,
          title: "Please enter start date",
        });
        return;
      } else if (!endDate) {
        Swal.fire({
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 1500,
          title: "Please enter end date",
        });
        return;
      }
      const pdf = await bulkDownLoadCsvService(startDate, endDate);
      const pdfResponse = pdf.data;
      const blob = new Blob([pdfResponse], { type: "text/csv" });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `allvendorinvoice.csv`;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("error", error);
    }
  };

  const renderData = () => {
    return vendorList?.map((item) => (
      <tr className="even:bg-[#FAFAFA] ">
        <td className="px-5 py-5   text-sm">
          <div className="flex items-center">
            <div className="flex-shrink-0 w-10 h-10">
              <img
                className="w-full h-full rounded-full"
                src={item.profileUrl ? item.profileUrl : placeholderImage}
                alt=""
                onError={onImageError}
              />
            </div>
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                {item.firstName ? item.firstName : "N/A"} {item.lastName}
              </p>
            </div>
          </div>
        </td>
        <td className="px-5 py-5   text-sm">
          <p className="text-gray-900 whitespace-no-wrap">
            {item.city ? item.city : "N/A"}
          </p>
        </td>
        <td className="px-5 py-5   text-sm">
          <p className="text-gray-900 whitespace-no-wrap">
            {item.dialCode} {item.phoneNumber ? item.phoneNumber : "N/A"}
          </p>
        </td>
        <td className="px-5 py-5   text-sm">
          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
            <span
              aria-hidden
              className="absolute inset-0  opacity-50 rounded-full"
            ></span>
            <span className="relative">
              {item.address ? item.address : "NA"}
            </span>
          </span>
        </td>
        <td className="px-5 py-5 mx-auto   ">
          <p
            className={
              item.verified
                ? "mx-auto bg-[#5AB344] text-white py-1 px-2 rounded-full text-xs"
                : "mx-auto bg-[#D10000] text-white py-1 px-2 rounded-full text-xs"
            }
          >
            {item.isActive ? "online" : "offline"}
          </p>
        </td>
        <td className="px-5 py-5 mx-auto  ">
          <p
            className={
              item?.verified.toLowerCase() === "approved"
                ? "mx-auto bg-[#5AB344] text-white py-1 px-2 rounded-full text-xs"
                : item?.verified.toLowerCase() === "pending"
                ? "mx-auto bg-[#F0E68C] text-black  py-1 px-2 rounded-full text-xs"
                : "mx-auto bg-[#D10000] text-white py-1 px-2 rounded-full text-xs"
            }
          >
            {item.verified}
          </p>
        </td>
        <td className="px-5 py-5   text-sm">
          <p
            className={` whitespace-no-wrap ${
              item?.platformFee ? "text-red-500" : "text-gray-900"
            } `}
          >
            {item.platformFee ? -item.platformFee.toFixed(2) : "0.0"}
          </p>
        </td>
        <td className="px-5 py-5   text-sm">
          <button
            className="border border-slate-400 p-2"
            onClick={() => handleDetails(item)}
          >
            Details
          </button>
        </td>
      </tr>
    ));
  };
  const csvPopUp = () => {
    return (
      <div className="bg-white w-[40%] relative z-30 flex flex-col gap-2 justify-between p-10 rounded-lg">
        <div
          className="flex justify-end text-[22px] font-bold text-[#5AB344] cursor-pointer"
          onClick={() => {
            setIsCsvPopupOpen(false);
          }}
        >
          X
        </div>
        <label htmlFor="startDate" className="text-[15px] font-bold">
          Start Date:
        </label>
        <input
          type="date"
          id="startDate"
          className="border-[1px] h-9  text-[15px] rounded-md px-2 border-[#5AB344]"
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
        />

        <label htmlFor="endDate" className="text-[15px] font-bold">
          End Date:
        </label>
        <input
          type="date"
          id="endDate"
          className="border-[1px] h-9  text-[15px] rounded-md px-2 border-[#5AB344]"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
        />
        <button
          className="mt-5 bg-[#5AB344] px-8 py-2 text-white shadow-lg rounded-full font-bold"
          type="button"
          onClick={() => {
            bulkCsvDownloadEvent();
          }}
        >
          Download
        </button>
      </div>
    );
  };
  const downloadAllVendorList = async () => {
    // bulkDownLoadVendorList
    try {
      const pdf = await bulkDownLoadVendorList();
      const pdfResponse = pdf.data;
      const blob = new Blob([pdfResponse], { type: "text/csv" });
      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `allvendorlist.csv`;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
      <main>
        <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
        <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
        {isCsvPopupOpen && (
          <div className=" bg-[#0000004d] absolute top-0 left-0 right-0 bottom-0 h-screen z-10 flex justify-center items-center">
            {csvPopUp()}
          </div>
        )}

        <section className="lg:ml-[18%] pt-[43%] md:pt-[23%] lg:pt-[5%] sm-[10%] h-full ">
          <div className="mt-8 p-5  bg-white  shadow-lg m-10">
            <div className="mb-2 flex justify-between">
              <h3 className="mb-2 text-2xl font-bold tracking-tight text-[#343434]">
                Vendors:-
              </h3>
              <div
                className="bg-[#5AB344] flex items-center h-8  gap-1 px-2 rounded-md cursor-pointer"
                onClick={() => {
                  setIsCsvPopupOpen(!isCsvPopupOpen);
                }}
              >
                <p className="text-[14px] text-white font-semibold">
                  Download All Vendor Orders
                </p>
                <svg
                  viewBox="0 0 384 512"
                  fill="white"
                  height="20px"
                  width="20px"
                  className="cursor-pointer"
                >
                  <path d="M64 0C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zm192 0v128h128L256 0zM80 224h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40v-80c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16h-17.6c-7.9 0-14.4 6.4-14.4 14.4 0 5.2 2.8 9.9 7.2 12.5l25.4 14.5C231 305.7 240 321 240 337.7c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4 0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5c-14.5-8.3-23.4-23.7-23.4-40.3zM280 240v31.6c0 23 5.5 45.6 16 66 10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                </svg>
              </div>
            </div>
            <div class="">
              <div class="flex gap-4">
                <input
                  onChange={(e) => {
                    filetrOrderBySearch(e);
                  }}
                  type="text"
                  placeholder="Search "
                  className="mt-1 block w-1/2 rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                />
                <input
                  onChange={(e) => {
                    filterByPhoneNumber(e);
                  }}
                  type="text"
                  placeholder="Search By Phone Number"
                  className="mt-1 block w-1/2 rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                />
              </div>
              <div class="my-3 flex gap-4 hidden">
                <input
                  onChange={(e) => {
                    filterByPincode(e);
                  }}
                  type="text"
                  placeholder="Search By Pincode"
                  className="mt-1 block w-1/2 rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                />
              </div>
            </div>
          </div>

          <div className="bg-white  shadow-lg mx-10 mb-10 mt-5 p-3">
            <div className="flex justify-end">
              <div
                className="bg-[#5AB344] flex items-center  gap-1 h-8 px-2 rounded-md cursor-pointer w-fit"
                onClick={() => {
                  downloadAllVendorList();
                }}
              >
                <p className="text-[14px] text-white font-semibold">
                  Download All Vendor List
                </p>
                <svg
                  viewBox="0 0 384 512"
                  fill="white"
                  height="20px"
                  width="20px"
                  className="cursor-pointer"
                >
                  <path d="M64 0C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zm192 0v128h128L256 0zM80 224h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40v-80c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16h-17.6c-7.9 0-14.4 6.4-14.4 14.4 0 5.2 2.8 9.9 7.2 12.5l25.4 14.5C231 305.7 240 321 240 337.7c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4 0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5c-14.5-8.3-23.4-23.7-23.4-40.3zM280 240v31.6c0 23 5.5 45.6 16 66 10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                </svg>
              </div>
            </div>
            <div className="-mx-4 px-4 sm:px-4 py-4 overflow-x-auto ">
              <table className="min-w-full border-none">
                <thead className="bg-[#EBFFDD] ">
                  <tr>
                    <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      City
                    </th>
                    <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Phone Number
                    </th>
                    <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Address
                    </th>
                    <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Verified
                    </th>
                    <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Platform Fee
                    </th>
                    <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>{renderData()}</tbody>
              </table>
              <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                <span className="text-xs xs:text-sm text-gray-900">
                  Total Vendors : {totalItemCount}
                </span>
                <div>
                  {vendorList && vendorList.length > 0 && (
                    <div className="pagination">
                      <span
                        onClick={() => selectPageHandler(page - 1)}
                        className={page > 1 ? "" : "pagination__disable"}
                      >
                        ◀
                      </span>

                      {Array.isArray(vendorList) &&
                        [...Array(Math.ceil(totalPageCount))].map((_, i) => {
                          return (
                            <span
                              key={i}
                              className={
                                page === i + 1 ? "pagination__selected" : ""
                              }
                              onClick={() => selectPageHandler(i + 1)}
                            >
                              {i + 1}
                            </span>
                          );
                        })}

                      <span
                        onClick={() => selectPageHandler(page + 1)}
                        className={
                          page < totalPageCount ? "" : "pagination__disable"
                        }
                      >
                        ▶
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Vendors;
