import { useEffect, useState } from "react";
import DashboardNav from "../Auth/Dashboard/Nav";
import Header from "../Auth/Dashboard/Header";
import { getAllPayments } from "../Service/PaymentsService";

function Payments() {
  const [vendorNav, setVendorNav] = useState(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  const [payments, setPayments] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const perPageCount = 10;
  const [totalItemCount, setTotalItemCount] = useState();
  const [filterPhoneNumber, setFilterPhoneNuber] = useState();

  const getAllPaymentsList = async (phoneNumber) => {
    const response = await getAllPayments(page - 1, perPageCount, phoneNumber);
    const { totalTransactionCount, transactions } = response;
    setTotalItemCount(totalTransactionCount);
    setPayments(transactions);
    const pageCount = Math.ceil(response.totalTransactionCount / perPageCount);
    setTotalPageCount(pageCount);
  };
  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPageCount &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const filterByPhoneNumber = async (event) => {
    let phoneNumber = {};
    try {
      phoneNumber = event.target.value;
      setFilterPhoneNuber(phoneNumber);
      await getAllPaymentsList(phoneNumber);
    } catch (error) {
      console.error("Search Error", error);
    }
  };

  const renderData = () => {
    return payments?.map((item, index) => (
      <tr className="even:bg-[#FAFAFA] " key={item._id}>
        <td className="px-5 py-5   text-sm">
          <p className="text-gray-900 whitespace-no-wrap">{index + 1}</p>
        </td>
        <td className="px-5 py-5   text-sm">
          <p className="text-gray-900 whitespace-no-wrap">
            {item.userInfo.firstName} {item.userInfo.lastName}
          </p>
        </td>
        <td className="px-5 py-5   text-sm">
          <p className="text-gray-900 whitespace-no-wrap">
            {item.transaction_id ? item.transaction_id : "N/A"}
          </p>
        </td>
        <td className="px-5 py-5   text-sm">
          <p className="text-gray-900 whitespace-no-wrap">
            {item.userInfo.phoneNumber ? item.userInfo.phoneNumber : "N/A"}
          </p>
        </td>
        <td className="px-5 py-5   text-sm">
          <p className="text-gray-900 whitespace-no-wrap">
            {item.amount ? item.amount : "N/A"}
          </p>
        </td>
        <td className="px-5 py-5 mx-auto   ">
          <p className="text-gray-900 whitespace-no-wrap">
            {item.before_balance === 0 ? 0 : item.before_balance.toFixed(2)}
          </p>
        </td>
        <td className="px-5 py-5 mx-auto  ">
          <p className="text-gray-900 whitespace-no-wrap">
            {item.after_balance === 0
              ? item.after_balance
              : item.after_balance.toFixed(2)}
          </p>
        </td>
      </tr>
    ));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPaymentsList();
  }, [page]);
  console.log("totalPageCount", totalPageCount);

  return (
    <main className="min-h-screen bg-slate-100">
      <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
      <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
      <section className="lg:ml-[18%] pt-[43%] md:pt-[23%] lg:pt-[5%] sm-[10%] h-full ">
        <div className="mt-8 p-5  bg-white  shadow-lg m-10">
          <div className="flex justify-between items-center">
            <h3 className="text-2xl font-bold tracking-tight text-[#343434]">
              Payments
            </h3>
            <input
              onChange={(e) => {
                filterByPhoneNumber(e);
              }}
              type="text"
              placeholder="Search By Phone Number"
              className="mt-1 block w-1/5 rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="bg-white  shadow-lg mx-10 mb-10 mt-5 p-5">
          <div className="-mx-4 -mt-5 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto ">
            <table className="min-w-full border-none">
              <thead className="bg-[#EBFFDD] ">
                <tr>
                  <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Sl.No
                  </th>
                  <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Transaction Id
                  </th>
                  <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Phone Number
                  </th>
                  <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Before Balence
                  </th>
                  <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    After Balence
                  </th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
            <div className="px-5 py-5 bg-white border-t flex flex-col ">
              <span className="text-xs xs:text-sm text-gray-900">
                Total Payments : {totalItemCount}
              </span>
              <div>
                {payments.length > 0 && (
                  <div className="pagination">
                    <span
                      onClick={() => selectPageHandler(page - 1)}
                      className={page > 1 ? "" : "pagination__disable"}
                    >
                      ◀
                    </span>
                    {Array.from({ length: totalPageCount }, (_, i) => (
                      <span
                        key={i}
                        className={page === i + 1 ? "pagination__selected" : ""}
                        onClick={() => selectPageHandler(i + 1)}
                      >
                        {i + 1}
                      </span>
                    ))}
                    <span
                      onClick={() => selectPageHandler(page + 1)}
                      className={
                        page < totalPageCount ? "" : "pagination__disable"
                      }
                    >
                      ▶
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Payments;
