import React, { useEffect, useState } from "react";
import DashboardNav from "../Auth/Dashboard/Nav";
import Header from "../Auth/Dashboard/Header";
import { useNavigate } from "react-router-dom";
import {
  deleteAuction,
  deleteAuctionCategory,
  getBidingList,
} from "../Service/Auction";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import Swal from "sweetalert2";
import showSucessMessage from "../message/showSucessMessage";

const Auction = () => {
  const [vendorNav, setVendorNav] = useState(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  const navigate = useNavigate();
  const [bidingList, setBidingList] = useState([]);
  const [page, setPage] = useState(1);
  const perPageCount = 10;
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState();

  const fetchData = async () => {
    try {
      const response = await getBidingList(page - 1, perPageCount);
      setBidingList(response?.bids);
      setTotalPageCount(Math.ceil(response.totalActionCount / perPageCount));
      setTotalItemCount(response.totalActionCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [page]);
  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPageCount &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };
  const deleteCategory = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the category!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5AB344",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            auctionId: id,
          };
          const result = await deleteAuction(payload);
          if (result) {
            showSucessMessage("Auction deleted successfully");
            fetchData();
          }
        } catch (error) {
        } finally {
        }
      }
    });
  };
  const getExactDateTime = (dateTime) => {
    if (!dateTime) {
      return "Invalid Date";
    }
    // Extract the date and time components directly from the string
    // Extract the date and time components directly from the string
    const datePart = dateTime.split("T")[0]; // "2024-08-16"
    const timePart = dateTime.split("T")[1].split(".")[0]; // "22:49:00"

    // Format the date according to your preferred format
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${day}/${month}/${year}`;

    // Convert the time to 12-hour format with AM/PM
    let [hours, minutes] = timePart.split(":");
    hours = parseInt(hours);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes} ${period}`;
    return `${formattedDate} ${formattedTime}`;
  };
  return (
    <>
      <main className="bg-slate-100 min-h-screen">
        <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
        <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
        <section className="lg:ml-[18%] pt-[33%] md:pt-[23%] lg:pt-[6%] sm-[10%] h-full relative">
          <section className="h-full">
            <div className="mt-8 p-5  bg-white  shadow-lg m-10">
              <div className="flex justify-between items-center">
                <div className="flex gap-5">
                  <h3 className="text-2xl font-bold tracking-tight text-[#343434]">
                    Auction and Biding
                  </h3>
                  <div>
                    <button
                      onClick={() => {
                        navigate("/add_auction");
                      }}
                      className="inline-block px-12 py-2 text-sm font-medium text-white bg-[#5AB344] border  rounded active:text-[#5AB344] hover:bg-transparent hover:text-[#5AB344] hover:border-2 hover:border-[#5AB344]  cursor-pointer focus:outline-none focus:ring"
                    >
                      Add Auction
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        // navigate("/add_auction");
                        // setIsCategoryOpen(true);
                        navigate("/biding_category");
                      }}
                      className="inline-block px-12 py-2 text-sm font-medium text-white bg-[#5AB344] border  rounded active:text-[#5AB344] hover:bg-transparent hover:text-[#5AB344] hover:border-2 hover:border-[#5AB344]  cursor-pointer focus:outline-none focus:ring"
                    >
                      Category
                    </button>
                  </div>
                </div>
                {/* <input
                  type="text"
                  placeholder="Search By Categories"
                  className="mt-1 block w-1/5 rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                /> */}
              </div>
            </div>
            <div className="bg-white  shadow-lg mx-10 mb-10 mt-5 p-5">
              <div className="-mx-4 -mt-5 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto ">
                <table className="min-w-full border-none">
                  <thead className="bg-[#EBFFDD] ">
                    <tr>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Sl.No
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        auctionId
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Auction Title
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Auction Description
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Auction Start Time
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Auction End Time
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Auction Starting Price
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Created Date
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Auction
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bidingList?.map((item, index) => (
                      <tr className="even:bg-[#FAFAFA] " key={index}>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {/* {index + 1} */}
                              {index + 1 + (page - 1) * perPageCount}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.auctionId ?? "N/A"}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.auctionTitle ?? "N/A"}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.auctionDescription ?? "N/A"}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(item?.startTime)?.toLocaleString()}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(item?.endTime)?.toLocaleString()}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.startingPrice}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(item?.createdAt)?.toLocaleString(
                                "en-US",
                                { timeZone: "Asia/Kolkata" }
                              )}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5">
                          <div className="ml-3 flex gap-5">
                            <span
                              onClick={() => {
                                navigate(`/view-auction?id=${item?.auctionId}`);
                              }}
                            >
                              <FaEye />
                            </span>
                            <span
                              className="text-[22px] cursor-pointer"
                              onClick={() => {
                                deleteCategory(item?.auctionId);
                              }}
                            >
                              <RiDeleteBin6Line />
                            </span>
                            <span
                              className="text-[22px] cursor-pointer hidden"
                              // onClick={() => {
                              //   setIsCategoryEditOpen(true);
                              //   setAuctionUpdateCategory({
                              //     ...auctionUpdateCategory,
                              //     category_name: item?.category_name,
                              //     category_description: item?.category_desc,
                              //     bidCategoryId: item?.bidCategoryId,
                              //   });
                              // }}
                            >
                              <CiEdit />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                  <span className="text-xs xs:text-sm text-gray-900">
                    Total Count : {totalItemCount}
                  </span>
                  <div>
                    {bidingList?.length > 0 && (
                      <div className="pagination">
                        <span
                          onClick={() => selectPageHandler(page - 1)}
                          className={page > 1 ? "" : "pagination__disable"}
                        >
                          ◀
                        </span>
                        {Array.from({ length: totalPageCount }, (_, i) => (
                          <span
                            key={i}
                            className={
                              page === i + 1 ? "pagination__selected" : ""
                            }
                            onClick={() => selectPageHandler(i + 1)}
                          >
                            {i + 1}
                          </span>
                        ))}
                        <span
                          onClick={() => selectPageHandler(page + 1)}
                          className={
                            page < totalPageCount ? "" : "pagination__disable"
                          }
                        >
                          ▶
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </main>
    </>
  );
};

export default Auction;
