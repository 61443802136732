import axiosInstance from "../api-config/axiosInstance";

const scrapService = async (obj, skip, perPageCount) => {
  try {
    // const response = await axiosInstance.get(`/vendor/getVendorOrder?page=0&limit=10&orderStatus=${queryString}&key=${(obj)?obj.key:null}`);
    const response = await axiosInstance.get(
      `/getScrap?page=${skip}&limit=${perPageCount}&key=${obj ? obj.key : null}`
    );

    const resposeParsing = JSON.parse(response.data.data);
    return resposeParsing;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const csvUploadservice = async (formData) => {
  try {
    const response = await axiosInstance.post(`/addBulkScrap`, formData);

    const resposeParsing = JSON.parse(response.data.data);
    return resposeParsing;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const csvUpdateUploadservice = async (formData) => {
  try {
    const response = await axiosInstance.post(`/updateBulkScrap`, formData);

    const resposeParsing = JSON.parse(response.data.data);
    return resposeParsing;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const downLoadScrapCsvService = async () => {
  console.log("downLoad Scrap Csv working");
  try {
    // const response = await axiosInstance.get(`/vendor/getVendorOrder?page=0&limit=10&orderStatus=${queryString}&key=${(obj)?obj.key:null}`);

    const response = await axiosInstance.get("/downloadScrapCsv");

    // const resposeParsing = JSON.parse(response.data.data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export {
  scrapService,
  csvUploadservice,
  downLoadScrapCsvService,
  csvUpdateUploadservice,
};
