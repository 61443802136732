import axiosInstance from "../api-config/axiosInstance";

export const getAllPayments = async ( skip, perPageCount, phoneNumber) => {
  try {
    const response = await axiosInstance.get(
      `/getTransactionList?page=${skip}&limit=${perPageCount}&phoneNumber=${phoneNumber ? phoneNumber : null}`
    );
    const resposeParsing = JSON.parse(response.data.data);
    console.log("getAllPayments Service data parsing", resposeParsing);
    return resposeParsing;
  } catch (error) {
    console.error("getAllPayments Error fetching data:", error);
  }
};