import React from 'react'

const RejectDetails = () => {
  return (
    <div>
       dn lfjnvjfl
    </div>
  )
}

export default RejectDetails
