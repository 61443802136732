import React, { useEffect, useState } from "react";
import image5 from "../assets/PNG/junk-bg.png";

import { useLocation, useNavigate } from "react-router-dom";
import showSucessMessage from "../message/showSucessMessage";
import showErrorMessage from "../message/showErrorMessage";
import DashboardNav from "../Auth/Dashboard/Nav";
import Header from "../Auth/Dashboard/Header";
import {
  assignAuctionCategory,
  getAuctionDetailsById,
} from "../Service/Auction";
import { CiEdit } from "react-icons/ci";
import { GiPodiumWinner } from "react-icons/gi";
import Swal from "sweetalert2";
import { FaIndianRupeeSign } from "react-icons/fa6";

const ViewAction = () => {
  const navigate = useNavigate();
  const [vendorNav, setVendorNav] = useState(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  const [isEnquiryOpen, setIsEnquiryOpen] = useState(false);
  const style = {
    common_style_1: "flex justify-between text-[19px] font-normal",
    common_style_2: "flex flex-row gap-5 flex-wrap",
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const auctionId = queryParams.get("id");
  const [bidingList, setBidingList] = useState([]);
  const [auctionDetails, setAuctionDetails] = useState();
  const [openPlaceBidOpen, setOpenPlaceBidOpen] = useState(false);
  const [bidedList, setBidedList] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getAuctionDetailsById(auctionId);
      setBidingList(response?.releatedBid);
      setAuctionDetails(response?.bidDetail[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    // fetchDataForLastBidedVendor();
  }, []);
  console.log("bidingList", bidingList);
  // const fetchDataForLastBidedVendor = async () => {
  //   try {
  //     const response = await getBidedDetailsById(auctionId);
  //     console.log("acution itemmmmm", response);
  //     setBidedList(response?.topBidder);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  console.log("auctionDetails.....", auctionDetails);
  const [bidedInput, setBidedInput] = useState();
  // const placeFinalBid = async () => {
  //   if (!bidedInput) {
  //     showErrorMessage("Please enter the price");
  //   }
  //   try {
  //     const payload = {
  //       amount: bidedInput,
  //       auctionId: auctionId,
  //     };
  //     const scrap = await addFinalBid(payload);
  //     if (scrap.statusCode === 200) {
  //       showSucessMessage("Bid added successfully");
  //       setOpenPlaceBidOpen(false);
  //       fetchData();
  //       fetchDataForLastBidedVendor();
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const assignBid = (id, vendorId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to assign this vendor!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5AB344",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            auctionId: id,
            vendorId: vendorId,
          };
          const result = await assignAuctionCategory(payload);
          console.log("result", result);
          if (result) {
            showSucessMessage("Auction deleted successfully");
            fetchData();
          }
        } catch (error) {
        } finally {
        }
      }
    });
  };

  const getExactDateTime = (dateTime) => {
    if (!dateTime) {
      return "Invalid Date";
    }
    // Extract the date and time components directly from the string
    // Extract the date and time components directly from the string
    const datePart = dateTime.split("T")[0]; // "2024-08-16"
    const timePart = dateTime.split("T")[1].split(".")[0]; // "22:49:00"

    // Format the date according to your preferred format
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${day}/${month}/${year}`;

    // Convert the time to 12-hour format with AM/PM
    let [hours, minutes] = timePart.split(":");
    hours = parseInt(hours);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes} ${period}`;
    return `${formattedDate} ${formattedTime}`;
    // console.log(`Date: ${formattedDate}, Time: ${formattedTime}`);
  };
  return (
    <main>
      <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
      <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
      <section className="lg:ml-[18%] pt-[43%] md:pt-[23%] lg:pt-[8%] bg-green-50 h-full p-2">
        <div className="h-full overflow-y-auto overflow-x-hidden flex flex-col relative">
          {/* Header Details */}
          <div className="bg-[#3cb042] flex flex-col w-full gap-2 px-10 py-5">
            <div className="text-white font-semibold text-[19px] flex flex-row gap-2 text-center">
              <p className="text-left h-fit ">Auction Id :</p>
              <p className="text-left">{auctionDetails?.auctionId}</p>
            </div>
            <div className="text-white font-semibold text-[19px] flex flex-row gap-2 text-center">
              <p className="  text-left h-fit ">Auction Title :</p>
              <p className="text-left w-fit">{auctionDetails?.auctionTitle}</p>
            </div>
            <div className="text-white font-semibold text-[19px] flex flex-row gap-2 text-center">
              <p className=" text-left h-fit ">Auction Description :</p>
              <p className="text-left">{auctionDetails?.auctionDescription}</p>
            </div>
          </div>
          {/* Image Carousel */}
          <div className="px-20 my-10 w-full mx-auto flex justify-center items-center">
            {auctionDetails?.images?.length == 0 ? (
              <p className="text-[26px] font-semibold">
                Auction images not available
              </p>
            ) : (
              <div
                className={`${style.common_style_2} w-full flex flex-wrap items-center justify-center`}
              >
                {auctionDetails?.images?.map((item_image, index) => (
                  <img
                    src={item_image?.aws_image_url}
                    alt="Auction Image"
                    className="rounded-md h-[300px] w-[250px] carousel-shadow"
                    key={index}
                    onError={(e) => {
                      e.target.src = image5;
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="h-full  flex flex-col justify-between">
            {/* Auction Details */}
            <div className="bg-gray-100 w-full">
              <div className="w-[50%] mx-auto flex flex-col gap-4 my-10">
                <div className={`${style.common_style_1}`}>
                  <p>Auction ID</p>
                  <p>{auctionDetails?.auctionId ?? "N/A"}</p>
                </div>
                {/* <div className={`${style.common_style_1}`}>
                  <p>Auction Type</p>
                  <p>Private</p>
                </div> */}
                <div className={`${style.common_style_1}`}>
                  <p>Start Time</p>
                  <p>{getExactDateTime(auctionDetails?.startTime)}</p>
                </div>
                <div className={`${style.common_style_1}`}>
                  <p>End Time</p>

                  <p>{getExactDateTime(auctionDetails?.endTime)}</p>
                </div>
                <div className={`${style.common_style_1}`}>
                  <p>Bidding Price</p>
                  <div className="flex items-center gap-1">
                    <p>{auctionDetails?.startingPrice}</p>
                    <p>
                      <FaIndianRupeeSign className="text-[16px]" />
                    </p>
                  </div>
                </div>
                {/* <div className={`${style.common_style_1}`}>
                  <p>Minimum Increment</p>
                  <p>{auctionDetails?.minimumIncrement}</p>
                </div> */}
                {/* <div className={`${style.common_style_1}`}>
                  <p>EMD Amount</p>
                  <p>INR 15, 00, 000</p>
                </div> */}
                {/* <div className={`${style.common_style_1}`}>
                  <p>Quantity</p>
                  <p>Approx 2,33,22 Kgs</p>
                </div> */}
                {/* <div className={`${style.common_style_1}`}>
                  <p>Location</p>
                  <p>Odisha</p>
                </div> */}
              </div>
            </div>
            {/* Related Listing */}
            <div className="px-5 w-full mx-auto mb-10">
              <p className="text-[26px] font-semibold mt-4">Bidded Vendor</p>
              <div className="w-full mt-5">
                <table className="min-w-full border-none">
                  <thead className="bg-[#EBFFDD] ">
                    <tr>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Sl.No
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        auctionId
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Vendor Name
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Vendor Phone Number
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Vendor Address
                      </th>
                      <th className="px-5 py-3   text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Vendor Bidded Price
                      </th>
                      <th className="px-5 py-3 flex justify-center items-center  text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Auction
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {auctionDetails?.placeBidInfo?.length > 0 &&
                      auctionDetails?.placeBidInfo?.map((item, index) => (
                        <tr className="even:bg-[#FAFAFA] " key={index}>
                          <td className="px-5 py-5   text-sm">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {/* {index + 1} */}
                                {index + 1}
                              </p>
                            </div>
                          </td>
                          <td className="px-5 py-5   text-sm">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {item?.auctionId ?? "N/A"}
                              </p>
                            </div>
                          </td>
                          <td className="px-5 py-5   text-sm">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {item?.userDetails?.firstName ?? "N/A"}{" "}
                                {item?.userDetails?.lastName ?? "N/A"}
                              </p>
                            </div>
                          </td>
                          <td className="px-5 py-5   text-sm">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {item?.userDetails?.phoneNumber ?? "N/A"}
                              </p>
                            </div>
                          </td>
                          <td className="px-5 py-5 text-sm">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {item?.userDetails?.address ?? "N/A"}
                              </p>
                            </div>
                          </td>
                          <td className="text-sm px-5 py-3">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap text-center ">
                                {item?.amount ?? "N/A"}
                              </p>
                            </div>
                          </td>
                          <td className="flex justify-center items-center">
                            <span
                              className={`text-[22px] flex items-center mt-4 cursor-pointer ${
                                auctionDetails?.auctionStatus == "Sold"
                                  ? "pointer-events-none"
                                  : ""
                              }`}
                              // onClick={() => {
                              //   setIsCategoryEditOpen(true);
                              //   setAuctionUpdateCategory({
                              //     ...auctionUpdateCategory,
                              //     category_name: item?.category_name,
                              //     category_description: item?.category_desc,
                              //     bidCategoryId: item?.bidCategoryId,
                              //   });
                              // }}
                              onClick={() => {
                                if (auctionDetails?.auctionStatus != "Sold") {
                                  assignBid(item?.auctionId, item?.placed_by);
                                }
                              }}
                            >
                              <GiPodiumWinner
                                className={`${
                                  item?.isWon == "won"
                                    ? "text-[#3cb042]"
                                    : item?.isWon == "loose"
                                    ? "text-red-500"
                                    : ""
                                } w-[35px] h-[35px]`}
                              />
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {auctionDetails?.placeBidInfo?.length <= 0 && (
                  <div className="flex justify-center items-center text-[22px] py-[10px] font-semibold">
                    <p className="text-center">No bid placed yet</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ViewAction;
