import React, { useEffect, useState } from "react";
import DashboardNav from "../Auth/Dashboard/Nav";
import Header from "../Auth/Dashboard/Header";
import { getRejectedOrderService, assignOrderToAdmin } from "../Service/Vendor";
import { Link } from "react-router-dom";

const RejectedOrder = () => {
  const OrdersRespEnum = {
    0: "Order In Pending",
    1: "Orders Accepted",
    2: "On the Way",
    3: "Arrived",
    4: "Picked The Scrap",
    5: "Order Rejected",
  };
  const [vendorNav, setVendorNav] = useState(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  const [rejectedList, setRejectedList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [rejectedListArray, seRejectedListArray] = useState([]);
  const getVendorInfo = async () => {
    try {
      const payload = {
        page: 0,
        limit: 100,
        filter: "",
      };
      const vendorResp = await getRejectedOrderService(payload);
      setRejectedList(vendorResp?.orders);
    } catch (error) {}
  };
  useEffect(() => {
    getVendorInfo();
  }, []);
  const dateTimeCalculate = (dateTime) => {
    // const dateString = '2024-01-17T17:52:39.640Z';
    const dateInUTC = new Date(dateTime);
    // Convert to IST (Indian Standard Time)
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const dateInIST = dateInUTC
      .toLocaleDateString("en-IN", options)
      .replace(/-/g, "/");
    const options2 = {
      timeZone: "Asia/Kolkata",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const istTime = dateInUTC.toLocaleTimeString("en-IN", options2);
    return `${dateInIST}, ${istTime}`;
  };
  const openRejectDetailsPopup = (RejectList) => {
    console.log("rejectedList",RejectList)
    setIsPopupOpen(true);
    seRejectedListArray(RejectList);
  };
  const singleOrderAssign = async () => {
    const orderId = rejectedListArray.orderId;
    const orderIdArray = [];
    orderIdArray.push(orderId);
    const payload = {
      orderId: orderIdArray,
    };
    const response = await assignOrderToAdmin(payload);
    if (response?.success) {
      getVendorInfo();
      setIsPopupOpen(false)
    }
  };
  const bulkOrderAssign = async () => {
    const bulkOrderId = [];
    rejectedList?.map((data) => {
      bulkOrderId.push(data?.orderId);
    });
    const payload = {
      orderId: bulkOrderId,
    };
    const response = await assignOrderToAdmin(payload);
    if (response?.success) {
      getVendorInfo();
    }
  };

  const renderRejectOrderDetails = () => {
    return (
      <div className=" bg-[#0000004d] absolute top-0 left-0 right-0 bottom-0 h-screen z-10 flex justify-center items-center">
        <div className="bg-white w-[45%] relative z-30 flex px-10 pb-10 pt-3 flex-col">
          <div>
            <p
              className="text-[22px] text-[#5AB344] flex justify-end cursor-pointer"
              onClick={() => {
                setIsPopupOpen(false);
              }}
            >
              X
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <p className="font-bold">Assign to Admin:</p>
            <input
              type="checkbox"
              className="cursor-pointer"
              onChange={() => {
                singleOrderAssign();
              }}
            />
          </div>
          <div className="flex gap-2 items-center">
            <p className="font-bold">Reason of rejection:</p>
            <p>{rejectedListArray?.rejectionMessage}</p>
          </div>
          <div>
            <div className="border-b-2">
              <div className="flex mt-6 mb-5">
                <h3 className="font-semibold text-gray-600 text-xs uppercase w-2/5">
                  Product Details
                </h3>
                <h3 className="font-semibold center text-gray-600 text-xs uppercase w-1/5 text-center">
                  Quantity
                </h3>
                <h3 className="font-semibold center text-gray-600 text-xs uppercase w-1/5 text-center">
                  Price
                </h3>
                <h3 className="font-semibold center text-gray-600 text-xs uppercase w-1/5 text-center">
                  Total
                </h3>
              </div>

              {rejectedListArray?.items?.map((scrapDetail, index) => (
                <div
                  key={index}
                  className="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5"
                >
                  <div className="flex w-2/5">
                    <div className="w-10">
                      <img
                        className="h-10"
                        src={scrapDetail?.scrapInfo.docUrl}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col justify-between ml-4 flex-grow">
                      <span className="font-bold text-sm">
                        {scrapDetail?.scrapInfo.scrapName}
                      </span>
                      <span className="text-red-500 text-sm">
                        {scrapDetail?.scrapInfo.quantityType}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-center w-1/5">
                    {scrapDetail?.quantity}
                  </div>
                  <span className="text-center w-1/5 font-semibold text-sm">
                    ₹{scrapDetail?.price}
                  </span>
                  <span className="text-center w-1/5 font-semibold text-sm">
                    ₹{scrapDetail?.amount}
                  </span>
                </div>
              ))}
            </div>
            <div>
              <p className="flex text-center justify-end mt-1 font-bold">
                Total - ₹ {rejectedListArray?.finalAmount}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <main className="">
        <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
        <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
        {isPopupOpen && renderRejectOrderDetails()}
        <section className="lg:ml-[18%] pt-[43%] md:pt-[23%] lg:pt-[10%] sm-[10%] h-full bg-white">
          <section className="h-full">
            <div
              id="NewRootRoot"
              className="flex flex-col w-full shadow bg-white"
            >
              <div className="flex-grow overflow-x-auto">
                <div className="py-2 inline-block w-full sm:px-6 lg:px-8">
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-2 items-center">
                      <p className="font-bold">Assign all orders to admin:</p>
                      <input
                        type="checkbox"
                        className="w-[17px] h-[17px] cursor-pointer"
                        onChange={() => {
                          bulkOrderAssign();
                        }}
                      />
                    </div>
                    <table className="min-w-full">
                      <thead className="rounded-xl">
                        <tr className="bg-[#EBFFDD] flex flex-row">
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl  w-[80px] flex justify-center items-center"
                          >
                            SN. No
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl  w-[250px] flex justify-center items-center"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl  w-[110px] flex justify-center items-center"
                          >
                            Order ID
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl w-[220px] flex justify-center items-center"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl w-[150px] flex justify-center items-center"
                          >
                            Phone
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl  w-[110px] flex justify-center items-center"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl w-[150px]  flex justify-center items-center"
                          >
                            Order Status
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl w-[150px] flex justify-center items-center"
                          >
                            Address
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl w-[150px] flex justify-center items-center"
                          >
                            Pincode
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl w-[280px] flex justify-center items-center"
                          >
                            Reason
                          </th>
                          <th
                            scope="col"
                            className="text-[16px] font-medium text-[#707070] px-3 py-4 text-left rounded-r-xl w-[120px]"
                          >
                            View Detail
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rejectedList?.map((item, index) => (
                          <tr
                            key={index}
                            className={` flex flex-row ${
                              index % 2 != 0 ? "bg-[#FAFAFA]" : "bg-white"
                            }`}
                          >
                            <td className="text-[16px] font-medium text-[#707070] text-left rounded-l-xl w-[80px] flex justify-center items-center">
                              {index + 1}
                            </td>
                            <td className="text-[16px] font-medium text-[#707070] text-left w-[250px] flex justify-center items-center">
                              {/* {item.createdAt} */}
                              {dateTimeCalculate(item?.createdAt)}
                            </td>
                            <td className="text-[16px] font-medium text-[#707070] text-left w-[110px] flex justify-center items-center">
                              {item.orderId}
                            </td>
                            <td className="text-[16px] font-medium text-[#707070] text-left w-[220px] flex justify-center items-center">
                              {item.fullName}
                            </td>
                            <td className="text-[16px] font-medium text-[#707070] text-left w-[150px] flex justify-center items-center">
                              {item.dialCode} {item.phoneNumber}
                            </td>
                            <td className="text-[16px] font-medium text-[#707070] text-left w-[110px] flex justify-center items-center">
                              {item.finalAmount}
                            </td>
                            <td
                              className={`text-[16px] font-medium text-[#707070] text-left  w-[150px] flex justify-center items-center ${
                                OrdersRespEnum[item.orderStatus] ==
                                "Orders Accepted"
                                  ? "text-green-500"
                                  : "text-red-500"
                              }`}
                            >
                              {OrdersRespEnum[item.orderStatus]}
                            </td>
                            <td className="text-[16px] font-medium text-[#707070] text-left  w-[150px] flex justify-center items-center">
                              {item?.addressInfo?.address}
                            </td>
                            <td className="text-[16px] font-medium text-[#707070] text-left  w-[150px] flex justify-center items-center">
                              {item?.addressInfo?.pincode}
                            </td>
                            <td className="text-[16px] font-medium text-[#707070] text-left  w-[280px] flex justify-center items-center">
                              {item?.rejectionMessage}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] font-normal w-[120px] text-[#707070] rounded-r-xl">
                              {/* <Link
                                to={`/rejectedOrderDetails/${item.orderId}`}
                              > */}
                              <button
                                className="bg-lime-600 text-white px-3 py-1 rounded"
                                type="button"
                                onClick={() => {
                                  openRejectDetailsPopup(item);
                                }}
                              >
                                Details
                              </button>
                              {/* </Link> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </main>
    </>
  );
};

export default RejectedOrder;
