import React, { useEffect, useState } from "react";
import DashboardNav from "../Auth/Dashboard/Nav";
import Header from "../Auth/Dashboard/Header";
import {
  deleteAuctionCategory,
  getBidingCategory,
  saveAuctionCategory,
  updateAuctionCategory,
} from "../Service/Auction";
import showSucessMessage from "../message/showSucessMessage";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import showErrorMessage from "../message/showErrorMessage";
import Swal from "sweetalert2";

const BidingCategory = () => {
  const [vendorNav, setVendorNav] = useState(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  const [bidingCateGory, setBidingCateGory] = useState([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isCategoryEditOpen, setIsCategoryEditOpen] = useState(false);
  const fetchData = async () => {
    try {
      const response = await getBidingCategory();
      setBidingCateGory(response?.bidCategoryDetail);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  const [auctionCategory, setAuctionCategory] = useState({
    category_name: "",
    category_description: "",
  });
  const [auctionUpdateCategory, setAuctionUpdateCategory] = useState({
    category_name: "",
    category_description: "",
    bidCategoryId: "",
  });
  const handleAddAuctionCategory = async () => {
    if (!auctionCategory.category_name) {
      showErrorMessage("Please fill category name");
      return;
    } else if (!auctionCategory?.category_description) {
      showErrorMessage("Please fill category description");
      return;
    } else {
      try {
        const payload = {
          category_name: auctionCategory?.category_name,
          category_desc: auctionCategory?.category_description,
        };
        const scrap = await saveAuctionCategory(payload);
        if (scrap.statusCode === 200) {
          showSucessMessage("Category added successfully");
          setIsCategoryOpen(false);
          fetchData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const handleUpdateAuctionCategory = async () => {
    if (!auctionUpdateCategory.category_name) {
      showErrorMessage("Please fill category name");
      return;
    } else if (!auctionUpdateCategory?.category_description) {
      showErrorMessage("Please fill category description");
      return;
    } else {
      try {
        const payload = {
          category_name: auctionUpdateCategory?.category_name,
          category_desc: auctionUpdateCategory?.category_description,
          bidCategoryId: auctionUpdateCategory?.bidCategoryId,
        };
        const scrap = await updateAuctionCategory(payload);
        if (scrap.statusCode === 200) {
          showSucessMessage("Category update successfully");
          setIsCategoryEditOpen(false);
          fetchData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const addCategory = () => {
    return (
      <div className="bg-white w-[40%] rounded-lg flex flex-col gap-3">
        <div className="bg-[#5AB344] p-3 text-white text-[20px] flex justify-center items-center">
          <p className="font-bold w-[95%] flex justify-center items-center">
            Add Category
          </p>
          <p
            className="text-[20px] w-[5%] font-bold  cursor-pointer"
            onClick={() => {
              setIsCategoryOpen(false);
            }}
          >
            X
          </p>
        </div>
        <div className="flex flex-col gap-3 p-6">
          <div className="flex flex-col gap-2">
            <p className="font-bold">Category Name :</p>

            <input
              className="border-[1px] border-black w-full rounded-md px-2 py-1"
              onChange={(e) => {
                setAuctionCategory({
                  ...auctionCategory,
                  category_name: e.target.value,
                });
              }}
              type="text"
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">Category Description :</p>

            <input
              className="border-[1px] border-black w-full rounded-md px-2 py-1"
              type="text"
              onChange={(e) => {
                setAuctionCategory({
                  ...auctionCategory,
                  category_description: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex justify-end gap-5 mt-4">
            <button
              className="bg-red-500 px-8 py-2 text-white shadow-lg rounded-full font-bold"
              type="button"
              onClick={() => {
                setIsCategoryOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className="bg-[#5AB344] px-8 py-2 text-white shadow-lg rounded-full font-bold"
              type="button"
              onClick={handleAddAuctionCategory}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  };
  const updateCategory = () => {
    return (
      <div className="bg-white w-[40%] rounded-lg flex flex-col gap-3">
        <div className="bg-[#5AB344] p-3 text-white text-[20px] flex justify-center items-center">
          <p className="font-bold w-[95%] flex justify-center items-center">
            Update Category
          </p>
          <p
            className="text-[20px] w-[5%] font-bold  cursor-pointer"
            onClick={() => {
              setIsCategoryEditOpen(false);
            }}
          >
            X
          </p>
        </div>
        <div className="flex flex-col gap-3 p-6">
          <div className="flex flex-col gap-2">
            <p className="font-bold">Category Name :</p>
            <input
              className="border-[1px] border-black w-full rounded-md px-2 py-1"
              onChange={(e) => {
                setAuctionUpdateCategory({
                  ...auctionUpdateCategory,
                  category_name: e.target.value,
                });
              }}
              type="text"
              value={auctionUpdateCategory?.category_name}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">Category Description :</p>
            <input
              className="border-[1px] border-black w-full rounded-md px-2 py-1"
              type="text"
              onChange={(e) => {
                setAuctionUpdateCategory({
                  ...auctionUpdateCategory,
                  category_description: e.target.value,
                });
              }}
              value={auctionUpdateCategory?.category_description}
            />
          </div>
          <div className="flex justify-end gap-5 mt-4">
            <button
              className="bg-red-500 px-8 py-2 text-white shadow-lg rounded-full font-bold"
              type="button"
              onClick={() => {
                setIsCategoryEditOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className="bg-[#5AB344] px-8 py-2 text-white shadow-lg rounded-full font-bold"
              type="button"
              onClick={handleUpdateAuctionCategory}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  };
  const deleteCategory = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the category!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5AB344",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            bidCategoryId: id,
          };
          const result = await deleteAuctionCategory(payload);
          if (result) {
            showSucessMessage("Category deleted successfully");
            fetchData();
          }
        } catch (error) {
        } finally {
        }
      }
    });
  };
  return (
    <>
      <main className="bg-green-50 min-h-screen">
        <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
        <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
        <section className="lg:ml-[18%]  md:pt-[23%] lg:pt-[5%]  h-full relative">
          {isCategoryOpen && (
            <div className="bg-[#0000004d] fixed top-0 left-0 right-0 bottom-0 h-screen z-50 flex justify-center items-center">
              {addCategory()}
            </div>
          )}
          {isCategoryEditOpen && (
            <div className="bg-[#0000004d] fixed top-0 left-0 right-0 bottom-0 h-screen z-50 flex justify-center items-center">
              {updateCategory()}
            </div>
          )}

          <div className="mt-8 p-5  bg-white  shadow-lg m-10">
            <div className="flex justify-between items-center">
              <div className="flex gap-8">
                <h3 className="text-2xl font-bold tracking-tight text-[#343434]">
                  Category Lists
                </h3>
                <div>
                  <button
                    onClick={() => {
                      // navigate("/add_auction");
                      setIsCategoryOpen(true);
                    }}
                    className="inline-block px-12 py-2 text-sm font-medium text-white bg-[#5AB344] border  rounded active:text-[#5AB344] hover:bg-transparent hover:text-[#5AB344] hover:border-2 hover:border-[#5AB344]  cursor-pointer focus:outline-none focus:ring"
                  >
                    Add Category
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-white  shadow-lg mx-10 mb-10 mt-5 p-5">
              <div className="-mx-4 -mt-5 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto ">
                <table className="min-w-full border-none">
                  <thead className="bg-[#EBFFDD] ">
                    <tr>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Sl.No
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Category Id
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Category Name
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Category Description
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Auction
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bidingCateGory?.map((item, index) => (
                      <tr className="even:bg-[#FAFAFA] " key={index}>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {index + 1}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.bidCategoryId}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.category_name}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.category_desc}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.status}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5   text-sm">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(item?.createdAt)?.toLocaleString()}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5">
                          <div className="ml-3 flex gap-5">
                            <span
                              className="text-[22px] cursor-pointer"
                              onClick={() => {
                                deleteCategory(item?.bidCategoryId);
                              }}
                            >
                              <RiDeleteBin6Line />
                            </span>
                            <span
                              className="text-[22px] cursor-pointer"
                              onClick={() => {
                                setIsCategoryEditOpen(true);
                                setAuctionUpdateCategory({
                                  ...auctionUpdateCategory,
                                  category_name: item?.category_name,
                                  category_description: item?.category_desc,
                                  bidCategoryId: item?.bidCategoryId,
                                });
                              }}
                            >
                              <CiEdit />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default BidingCategory;
