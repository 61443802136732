import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardNav from "../Auth/Dashboard/Nav";
import Header from "../Auth/Dashboard/Header";
import "reactjs-popup/dist/index.css";
import "../style/Scrap.css";
import {
  scrapService,
  csvUploadservice,
  downLoadScrapCsvService,
  csvUpdateUploadservice,
} from "../Service/ScrapService";
import showErrorMessage from "../message/showErrorMessage";

const UploadScrap = () => {
  const [vendorNav, setVendorNav] = useState(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  const navigate = useNavigate();
  const [scrapList, setScrapList] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const perPageCount = 10;
  const [totalItemCount, setTotalItemCount] = useState();
  const [isCsvPopupOpen, setIsCsvPopupOpen] = useState(false);
  const [isUpdateCsvPopupOpen, setIsUpdateCsvPopupOpen] = useState(false);
  const [csvFile, setCsvFile] = useState();
  const [updateCsvFile, setUpdateCsvFile] = useState();
  const placeholderImage =
    "https://play-lh.googleusercontent.com/93TI5hqzUF7_i61dah3PexL9DktIgsExTutymOXUkd7hdjlSx1P-3ZE0T-uZ2bnF5MXq";
  const onImageError = (e) => {
    e.target.src = placeholderImage;
  };

  // useEffect to log imageKey when it changes
  useEffect(() => {}, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    scrapFind();
  }, [page]);

  const scrapFind = async (obj) => {
    try {
      const scrap = await scrapService(obj, page - 1, perPageCount);
      setScrapList(scrap.scrap);
      setTotalItemCount(scrap.totalScrapCount);
      setTotalPageCount(Math.ceil(scrap.totalScrapCount / perPageCount));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (item) => {
    navigate("/editScrap", {
      state: {
        item,
      },
    });
  };

  const handleScrap = () => {
    navigate("/AddScrap");
  };

  const handleDetails = (item) => {
    navigate("/ScrapDetails", {
      state: {
        item,
      },
    });
  };

  const filetrOrderBySearch = async (event) => {
    // setSearchFilter(event.target.value);
    let obj = {};
    try {
      obj.key = event.target.value;
      // console.log("searchFilter", obj, filterOrderStatus);
      await scrapFind(obj);
    } catch (error) {
      console.error("Search Error", error);
    }
  };

  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPageCount &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const renderData = () => {
    return scrapList?.map((item) => (
      <tr className="even:bg-[#FAFAFA] ">
        <td class="px-5 py-5   text-sm">
          <p class="text-[#707070] whitespace-no-wrap">#{item.scrapId}</p>
        </td>
        <td class="px-5 py-5  text-sm">
          <div class="flex items-center">
            <div class="flex-shrink-0 w-10 h-10">
              <img
                class="w-full h-full rounded-full"
                src={item.docUrl ? item.docUrl : placeholderImage}
                alt=""
              />
            </div>
            <div class="ml-3">
              <p class="text-[#000000] whitespace-no-wrap">{item.scrapName}</p>
            </div>
          </div>
        </td>
        <td class="px-5 py-5   text-sm">
          <p class="text-[#707070] whitespace-no-wrap">{item.quantityType}</p>
        </td>
        <td class="px-5 py-5  text-sm">
          <p class="text-[#707070] whitespace-no-wrap">{item.price}</p>
        </td>
        {/* <td class="px-5 py-5  text-sm">
          <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
            <span
              aria-hidden
              class="absolute inset-0  opacity-50 rounded-full"
            ></span>
           
          </span>
        </td> */}

        <td class="px-5 py-5  text-sm">
          <button
            className="border-2 p-2 border-[#95989A80]"
            onClick={(e) => handleDetails(item)}
          >
            Details
          </button>
          <button
            className="ml-2 p-2 border-2 border-[#95989A80]"
            onClick={(e) => handleEdit(item)}
          >
            Edit
          </button>
        </td>
      </tr>
    ));
  };
  const uploadScrapCsv = async (e) => {
    try {
      const file = e.target.files[0];
      setCsvFile(file);
    } catch (error) {
      console.error("habdle scrap csv", error);
    }
  };
  const csvUploadEvent = async () => {
    try {
      if (!csvFile) {
        showErrorMessage("Please add a csv file");
        return;
      }
      const formData = new FormData();
      formData.append("scrapCsv", csvFile);
      await csvUploadservice(formData);
    } catch (error) {
      console.error("error message", error);
    }
  };
  const csvUpdateUploadEvent = async () => {
    try {
      if (!updateCsvFile) {
        showErrorMessage("Please add a csv file");
        return;
      }
      const formData = new FormData();
      formData.append("scrapCsv", updateCsvFile);
      const data = await csvUpdateUploadservice(formData);
      setIsUpdateCsvPopupOpen(false);
    } catch (error) {
      console.error("error message", error);
    }
  };
  const csvPopUp = () => {
    return (
      <div className="bg-white w-[50%] relative z-30 flex flex-col gap-2 justify-between p-10 rounded-lg">
        <div
          className="flex justify-end text-[22px] font-bold text-[#5AB344] cursor-pointer"
          onClick={() => {
            setIsCsvPopupOpen(false);
          }}
        >
          X
        </div>
        <label htmlFor="startDate">Upload Csv:</label>
        <input type="file" id="uploadcsv" onChange={uploadScrapCsv} />

        <button
          className="mt-5 bg-[#5AB344] px-8 py-2 text-white shadow-lg rounded-full font-bold"
          type="button"
          onClick={() => {
            csvUploadEvent();
          }}
        >
          Submit
        </button>
      </div>
    );
  };
  const updateCSVPopUp = () => {
    return (
      <div className="bg-white w-[40%] relative z-30 flex flex-col gap-2 justify-between p-10 rounded-lg">
        <div
          className="flex justify-end text-[22px] font-bold text-[#5AB344] cursor-pointer"
          onClick={() => {
            setIsUpdateCsvPopupOpen(false);
          }}
        >
          X
        </div>
        <label htmlFor="startDate">Upload Csv:</label>
        <input
          type="file"
          id="uploadcsv"
          onChange={(e) => {
            const file = e.target.files[0];
            setUpdateCsvFile(file);
          }}
        />

        <button
          className="mt-5 bg-[#5AB344] px-8 py-2 text-white shadow-lg rounded-full font-bold"
          type="button"
          onClick={() => {
            csvUpdateUploadEvent();
          }}
        >
          Submit
        </button>
      </div>
    );
  };
  const scrapCsvDownloadEvent = async () => {
    try {
      const currentTime = new Date().getTime();
      const pdf = await downLoadScrapCsvService();
      const pdfResponse = pdf.data;
      const blob = new Blob([pdfResponse], { type: "text/csv" });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `scrap_${currentTime}.csv`;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("error", error);
    }
  };
  console.log("scrapList", scrapList);
  return (
    <>
      <main className="bg-slate-100">
        <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
        <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
        <section className="lg:ml-[18%] pt-[43%] md:pt-[23%] lg:pt-[7%] sm-[10%] h-full ">
          <div class="p-6 lg:grid lg:grid-cols-2 lg:gap-8 bg-white  shadow-lg m-10">
            <div href="#" class="block p-6 mb-6   lg:mb-0">
              <button
                onClick={scrapCsvDownloadEvent}
                className="w-2/5 h-[30px] text-white font-extrabold bg-[#81D742] rounded-[30px]"
              >
                Download Csv
              </button>
            </div>

            <div class="block p-6 mb-6    lg:mb-0">
              <form class="flex flex-col justify-left d md:flex-row gap-3">
                <div class="flex">
                  <input
                    onChange={(e) => {
                      filetrOrderBySearch(e);
                    }}
                    type="text"
                    placeholder="Search "
                    class="w-full md:w-80 px-3 h-10 rounded-l border-2  focus:outline-none f"
                  />
                </div>
              </form>
            </div>
          </div>

          <div className=" lg:grid lg:grid-cols-2 lg:gap-8 mr-10">
            <div className="block p-6    lg:mb-0"></div>
            <div class="block   justify-end   lg:mb-0">
              <form class="flex flex-col items-end justify-end md:flex-row ">
                <div>
                  {isCsvPopupOpen && (
                    <div className=" bg-[#0000004d] fixed top-0 left-0 right-0 bottom-0 h-screen z-50 flex justify-center items-center">
                      {csvPopUp()}
                    </div>
                  )}
                  {isUpdateCsvPopupOpen && (
                    <div className=" bg-[#0000004d] fixed top-0 left-0 right-0 bottom-0 h-screen z-50 flex justify-center items-center">
                      {updateCSVPopUp()}
                    </div>
                  )}
                  <svg
                    viewBox="0 0 384 512"
                    fill="currentColor"
                    height="2em"
                    width="2em"
                    onClick={() => {
                      setIsCsvPopupOpen(!isCsvPopupOpen);
                    }}
                    className="cursor-pointer"
                  >
                    <path d="M64 0C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zm192 0v128h128L256 0zM80 224h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40v-80c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16h-17.6c-7.9 0-14.4 6.4-14.4 14.4 0 5.2 2.8 9.9 7.2 12.5l25.4 14.5C231 305.7 240 321 240 337.7c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4 0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5c-14.5-8.3-23.4-23.7-23.4-40.3zM280 240v31.6c0 23 5.5 45.6 16 66 10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                  </svg>
                </div>
                <div class="block relative ml-5">
                  <button
                    onClick={handleScrap}
                    className="inline-block px-12 py-2 text-sm font-medium text-white bg-[#5AB344] border bg-[#5AB344] rounded active:text-[#5AB344] hover:bg-transparent hover:text-[#5AB344] cursor-pointer focus:outline-none focus:ring"
                  >
                    Add Scrap
                  </button>
                </div>
                <div class="block relative ml-5">
                  <button
                    onClick={() => {
                      setIsUpdateCsvPopupOpen(true);
                    }}
                    className="inline-block px-12 py-2 text-sm font-medium text-white bg-[#5AB344] border bg-[#5AB344] rounded active:text-[#5AB344] hover:bg-transparent hover:text-[#5AB344] cursor-pointer focus:outline-none focus:ring"
                    type="button"
                  >
                    Update Scrap
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="bg-white  shadow-lg m-10 p-5">
            <div class="-mx-4 -mt-5 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto ">
              <div class=" min-w-full  overflow-hidden">
                <table class="min-w-full border-none">
                  <thead className="bg-[#EBFFDD] ">
                    <tr className="rounded">
                      <th class="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Scrap ID
                      </th>
                      <th class="px-5 py-3   text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Scrap Name
                      </th>
                      <th class="px-5 py-3  text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Quantity Type
                      </th>
                      <th class="px-5 py-3 0 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Price
                      </th>

                      <th class="px-5 text-start py-3   text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderData()}</tbody>
                </table>
                <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                  <span class="text-xs xs:text-sm text-gray-900">
                    Total Scrap : {totalItemCount}
                  </span>
                  <div>
                    {scrapList && scrapList.length > 0 && (
                      <div className="pagination">
                        <span
                          onClick={() => selectPageHandler(page - 1)}
                          className={page > 1 ? "" : "pagination__disable"}
                        >
                          ◀
                        </span>

                        {Array.isArray(scrapList) &&
                          [...Array(Math.ceil(totalPageCount))].map((_, i) => {
                            return (
                              <span
                                key={i}
                                className={
                                  page === i + 1 ? "pagination__selected" : ""
                                }
                                onClick={() => selectPageHandler(i + 1)}
                              >
                                {i + 1}
                              </span>
                            );
                          })}

                        <span
                          onClick={() => selectPageHandler(page + 1)}
                          className={
                            page < totalPageCount ? "" : "pagination__disable"
                          }
                        >
                          ▶
                        </span>
                      </div>
                    )}
                  </div>
                  {/* <div class="inline-flex mt-2 xs:mt-0">
                    <PaginationComponent
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default UploadScrap;
