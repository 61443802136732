import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DashboardNav from "../Auth/Dashboard/Nav.jsx";
import Header from "../Auth/Dashboard/Header.jsx";
import { vendorService } from "../Service/Vendor.js";
import { IoIosArrowUp } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { addAuction, getBidingCategory } from "../Service/Auction.jsx";
import showErrorMessage from "../message/showErrorMessage.jsx";
import showSucessMessage from "../message/showSucessMessage.jsx";
import Loader from "../Components/Loader/Loader.jsx";

const AddAuction = () => {
  const [vendorNav, setVendorNav] = useState(false);
  const handleVendorNav = () => setVendorNav(true);
  const closeVendorNav = () => setVendorNav(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [addImagesCount, setAddImagesCount] = useState(1);
  const [bidingCateGory, setBidingCateGory] = useState([]);
  const [images, setImages] = useState([]);
  // Handle image selection and conversion to Base64
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    // const files=event.target.files[0]
    setImages(files);
  };
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    bidCategoryId: "",
    startTime: "",
    EndTime: "",
    startingPrice: "",
    minimumIncrement: "",
    bid_images: "",
  });
  const uploadData = async () => {
    if (!formData?.title) {
      showErrorMessage("Please fill the title");
      return;
    } else if (!formData?.description) {
      showErrorMessage("Please fill the description");
      return;
    } else if (!formData?.bidCategoryId) {
      showErrorMessage("Please select the category");
      return;
    } else if (!formData?.startingPrice) {
      showErrorMessage("Please fill the starting price");
      return;
    } else if (!formData?.startTime) {
      showErrorMessage("Please fill the starting time");
      return;
    } else if (!formData?.EndTime) {
      showErrorMessage("Please fill the ending time");
      return;
    } else if (!formData?.minimumIncrement) {
      showErrorMessage("Please fill the minimum increment value");
      return;
    } else if (vendorIdArray?.length <= 0) {
      showErrorMessage("Please select the vendor");
      return;
    }
    const dataPayload = {
      auctionDescription: formData?.description,
      EndTime: formData?.EndTime,
      minimumIncrement: formData?.minimumIncrement,
      startingPrice: formData?.startingPrice,
      startTime: formData?.startTime,
      bidCategoryId: formData?.bidCategoryId,
      vendorId: vendorIdArray,
      auctionTitle: formData?.title,
      bid_images: images,
    };
    setSaveLoader(true);
    const formData1 = new FormData();
    Array.from(images).forEach((file, index) => {
      formData1.append("bid_images", file); // Add images to FormData
    });
    formData1.append("auctionDescription", formData?.description);
    formData1.append("EndTime", formData?.EndTime);

    formData1.append("minimumIncrement", formData?.minimumIncrement);

    formData1.append("startingPrice", formData?.startingPrice);

    formData1.append("startTime", formData?.startTime);

    formData1.append("bidCategoryId", formData?.bidCategoryId);
    formData1.append("vendorId", vendorIdArray);
    formData1.append("auctionTitle", formData?.title);

    try {
      const resp = addAuction(formData1);
      //   const resp = await axiosInstance.post("/saveBid", dataPayload);
      const response = resp
        .then((data) => {
          const res_data = data?.data;
          if (res_data?.statusCode === 200) {
            setSaveLoader(false);
            showSucessMessage(res_data.message);
            setFormData({
              ...formData,
              title: "",
              description: "",
              bidCategoryId: "",
              startTime: "",
              EndTime: "",
              startingPrice: "",
              minimumIncrement: "",
            });
            setVendorIdArray([]);
            setVendorId([]);
            setImages([]);
            window.location.reload();
          }
        })
        .finally(setSaveLoader(false));
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        // If server responded with a status code for a request
        Swal.fire({
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 2500,
          title: "Something Went Wrong",
        });
      } else if (error.request) {
        // Client made a request but response is not received
        console.log(error.request);
      } else {
        // Other case
        console.log("Error", error.message);
      }
    }
  };
  const AddImages = () => {
    setAddImagesCount(addImagesCount + 1);
  };
  const [vendorList, setVendorList] = useState([]);
  const [page, setPage] = useState(1);
  const perPageCount = 10;

  const fetchData = async (obj, phoneNumber, pincode) => {
    try {
      const response = await vendorService(obj, "", "", phoneNumber, pincode);
      setVendorList(response.vendor);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  const [openVendorDropDown, setOpenVendorDropDown] = useState(false);
  const [vendorId, setVendorId] = useState([]);
  const placeholderImage =
    "https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp";
  const onImageError = (e) => {
    e.target.src = placeholderImage;
  };
  const [vendorIdArray, setVendorIdArray] = useState([]);
  const handleCheckboxChange = (id, name) => {
    setVendorId((prevVendorData) => {
      const existingVendor = prevVendorData.find((vendor) => vendor.id === id);
      if (existingVendor) {
        // If the ID is already in the array, remove it
        return prevVendorData.filter((vendor) => vendor.id !== id);
      } else {
        // If the ID is not in the array, add it
        return [...prevVendorData, { id, name }];
      }
    });
    const existingVendor = vendorIdArray?.find((vendor) => vendor === id);
    if (existingVendor) {
      // If the ID is already in the array, remove it
      setVendorIdArray(vendorIdArray.filter((vendor) => vendor !== id));
    } else {
      // If the ID is not in the array, add it
      setVendorIdArray([...vendorIdArray, id]);
    }
  };
  const fetchDataForCategory = async () => {
    try {
      const response = await getBidingCategory();
      setBidingCateGory(response?.bidCategoryDetail);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDataForCategory();
  }, []);

  return (
    <>
      <main className="bg-green-50 min-h-screen">
        <Loader show={saveLoader} />
        <DashboardNav showNav={vendorNav} hideNav={closeVendorNav} />
        <Header handleNavClick={handleVendorNav} showNav={vendorNav} />
        <section className="lg:ml-[18%]  md:pt-[23%] lg:pt-[5%]  h-full ">
          <div className="w-full flex justify-center  p-4 sm:mt-[20px] ">
            <div className="w-full md:w-[50%] mb-4 md:mb-0 bg-white shadow-lg p-[20px]">
              <div className="w-[100%] p-[10px]">
                <h1 className="text-[35px] font-sans font-bold text-black text-center">
                  Add Auction
                </h1>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <div>
                  <label className="block py-3 text-black">Enter Title</label>
                  <div className="flex items-center p-2 border rounded-md bg-[#80d7421c]">
                    <input
                      type="text"
                      value={formData?.title}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          title: e.target.value,
                        })
                      }
                      placeholder="Enter Auction Title"
                      className="w-full p-1 ml-3 text-black outline-none bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <div>
                  <label className="block py-3 text-black">
                    Enter description
                  </label>
                  <div className="flex items-center p-2 border rounded-md bg-[#80d7421c]">
                    <input
                      type="text"
                      value={formData?.description}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          description: e.target.value,
                        })
                      }
                      placeholder="Enter Auction Description"
                      className="w-full p-1 ml-3 text-black outline-none bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <div>
                  <label className="block py-3 text-black">
                    Select Category
                  </label>
                  <div className="flex items-center p-2 border rounded-md bg-[#80d7421c]">
                    <div className="w-full">
                      <select
                        className="w-full border-slate-50"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            bidCategoryId: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Category</option>
                        {bidingCateGory?.map((option) => (
                          <option
                            value={option.bidCategoryId}
                            id={option?.bidCategoryId}
                          >
                            {option.category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <label className="block py-3 text-black">Select Vendor</label>
                  <div className="w-full">
                    <div>
                      <div
                        className="bg-[#80d7421c] rounded-md shadow-md p-3 flex cursor-pointer justify-between items-center"
                        onClick={() => {
                          setOpenVendorDropDown(!openVendorDropDown);
                        }}
                      >
                        <span className="flex gap-2 flex-wrap">
                          {vendorId?.map((item) => (
                            <p className="flex gap-2 p-[6px] rounded-md font-medium bg-gray-300 text-[14px]">
                              {item?.name == "undefined undefined"
                                ? "N/A"
                                : item?.name}
                            </p>
                          ))}
                        </span>
                        <div className="flex gap-4">
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              setOpenVendorDropDown(false);
                            }}
                          >
                            <IoClose />
                          </span>

                          <span className="-rotate-180 cursor-pointer">
                            <IoIosArrowUp />
                          </span>
                        </div>
                      </div>
                      {openVendorDropDown && (
                        <div className="bg-[#80d7421c] shadow-md rounded-md h-[300px] overflow-y-auto">
                          <div className="flex justify-center items-center">
                            <input
                              type="number"
                              className="h-9 w-[90%] border-[1px] border-black shadow-lg m-1 pl-3"
                              placeholder="Search by number"
                              onChange={(e) => {
                                console.log("e.....",e.target.value)
                                fetchData("", e.target.value, "");
                              }}
                            />
                          </div>
                          {vendorList?.map((item) => (
                            <div className="flex justify-start gap-6 h-11 border-b-[1px]  border-gray-500 items-center px-3">
                              <div>
                                <input
                                  type="checkbox"
                                  className="cursor-pointer"
                                  onChange={() => {
                                    handleCheckboxChange(
                                      item?.userId,
                                      item?.firstName + " " + item?.lastName
                                    );
                                  }}
                                  checked={vendorIdArray?.includes(item?.userId)?true:false}
                                />
                              </div>
                              <img
                                className="h-7 w-7 rounded-full"
                                src={
                                  item.profileUrl
                                    ? item.profileUrl
                                    : placeholderImage
                                }
                                alt=""
                                onError={onImageError}
                              />
                              <p className="">
                                {item.firstName ? item.firstName : "N/A"}{" "}
                                {item.lastName}
                              </p>
                              <p className="text-[14px] font-semibold">
                                {item?.phoneNumber ?? "N/A"}
                              </p>
                              <p className="text-[14px] font-semibold">
                                {item?.address ?? "N/A"}
                              </p>
                              <p className="text-[14px] font-semibold">
                                {item?.pincode ?? "N/A"}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <div>
                  <label className="block py-3 text-black">
                    Start Date & Time
                  </label>
                  <div className="flex items-center p-2 border rounded-md bg-[#80d7421c]">
                    <input
                      value={formData?.startTime}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          startTime: e.target.value,
                        })
                      }
                      type="datetime-local"
                      placeholder="Enter Auction Description"
                      className="w-full p-1 ml-3 text-black outline-none bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <div>
                  <label className="block py-3 text-black">
                    End Date & Time
                  </label>
                  <div className="flex items-center p-2 border rounded-md bg-[#80d7421c]">
                    <input
                      value={formData?.EndTime}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          EndTime: e.target.value,
                        })
                      }
                      type="datetime-local"
                      placeholder="Enter Auction Description"
                      className="w-full p-1 ml-3 text-black outline-none bg-transparent"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-span-6 sm:col-span-3">
                <div>
                  <label className="block py-3 text-black">Biding Start Price</label>
                  <div className="flex items-center p-2 border rounded-md bg-[#80d7421c]">
                    <input
                      type="text"
                      inputMode="number"
                      //   value={formValues.price}
                      //   onChange={(e) =>
                      //     setFormValues({ ...formValues, price: e.target.value })
                      //   }
                      placeholder="Enter Price"
                      className="w-full pr-3 p-1 ml-3 text-black outline-none bg-transparent"
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-span-6 sm:col-span-3">
                <div>
                  <label className="block py-3 text-black">
                    Bidding Amount
                  </label>
                  <div className="flex items-center p-2 border rounded-md bg-[#80d7421c]">
                    <input
                      type="number"
                      value={formData?.startingPrice}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          startingPrice: e.target.value,
                        })
                      }
                      placeholder="Enter biding amount"
                      className="w-full pr-3 p-1 ml-3 text-black outline-none bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <div>
                  <label className="block py-3 text-black">
                    Enter the amount you need to increase
                  </label>
                  <div className="flex items-center p-2 border rounded-md bg-[#80d7421c]">
                    <input
                      type="number"
                      value={formData?.minimumIncrement}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          minimumIncrement: e.target.value,
                        })
                      }
                      placeholder="Enter the amount you need to increase"
                      className="w-full pr-3 p-1 ml-3 text-black outline-none bg-transparent"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className="" htmlFor="">
                  Upload Item image
                </p>
                <input
                  type="file"
                  onChange={handleImageChange}
                  multiple
                  placeholder="Add Images"
                  className="w-full pr-3 p-1 text-black border-[1px] border-[#81D742]"
                />
              </div>
              <br />

              <br />
              <div className="col-span-6 sm:col-span-3 mt-5">
                <button
                  onClick={uploadData}
                  type="button"
                  className="w-full h-[50px] text-white font-extrabold bg-[#81D742] rounded-[30px]"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddAuction;
